import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Privacy from "../components/privacy"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Privacy />
  </Layout>
)

export default PrivacyPage
